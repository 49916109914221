import { defineStore } from 'pinia'
import user from "~/pages/user.vue";
import type {User} from "~/types/User";

export const useProblemStore = defineStore('problemStore', () => {
    const showModal = ref(false)

    async function sendReport(text: string | null) {
        if (!text) return
        return useAsyncData(
            'sendReport',
            () => $fetch('/api/problem-report', {
                method: 'POST',
                body: {
                    text: text,
                    url: location.href
                },
            })
        )
    }

    return {
        showModal,
        sendReport
    }
})
