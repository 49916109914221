<!-- components/GlobalLoading.vue -->
<template>
  <div v-if="loadingStore.isLoading" class="fixed inset-0 bg-custom-green-dark bg-opacity-75 flex items-center justify-center z-[500]">
    <div class="text-center flex flex-col items-center">
      <div class="loader animate-spin"></div>
      <p class="mt-4 text-white font-semibold text-lg">Bitte warten ...</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '@/stores/loading'

const loadingStore = useLoadingStore()
</script>

<style scoped>
.loader {
  border: 10px solid rgba(255, 255, 255, 0.5);
  border-top: 10px solid #ffffff;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
</style>
