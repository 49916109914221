import { default as activateWt1MltF0fhMeta } from "/app/pages/activate.vue?macro=true";
import { default as archivek5fmITgSJqMeta } from "/app/pages/archive.vue?macro=true";
import { default as codeP0oXibevQLMeta } from "/app/pages/book-locker/code.vue?macro=true";
import { default as DEPRECATED_collecting0oMjZgRmSLMeta } from "/app/pages/book-locker/DEPRECATED_collecting.vue?macro=true";
import { default as finishU0BHm8GojhMeta } from "/app/pages/book-locker/finish.vue?macro=true";
import { default as involved9CkJ6M2P6kMeta } from "/app/pages/book-locker/involved.vue?macro=true";
import { default as locationFmitPNWyhWMeta } from "/app/pages/book-locker/location.vue?macro=true";
import { default as locker_45size3dFHL15ChvMeta } from "/app/pages/book-locker/locker-size.vue?macro=true";
import { default as timerange44YsbDEAwDMeta } from "/app/pages/book-locker/timerange.vue?macro=true";
import { default as book_45lockerJEhdloGqqgMeta } from "/app/pages/book-locker.vue?macro=true";
import { default as collectDYhiYvZTDGMeta } from "/app/pages/collect.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as deliverWYIcH8LVKyMeta } from "/app/pages/deliver.vue?macro=true";
import { default as firstStartSigninI92BYQKEl8Meta } from "/app/pages/firstStartSignin.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as new_45passwordjRHibjZFxMMeta } from "/app/pages/new-password.vue?macro=true";
import { default as notifications883AI3QFtaMeta } from "/app/pages/notifications.vue?macro=true";
import { default as check_45data9CCxnQ9mmQMeta } from "/app/pages/onboarding/driver/check-data.vue?macro=true";
import { default as indexoiPDB37jPRMeta } from "/app/pages/onboarding/driver/index.vue?macro=true";
import { default as password_45reset39IAnjWvPiMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as _91uid_93nELhhQcqisMeta } from "/app/pages/put-in/[uid].vue?macro=true";
import { default as listIzlsMZUP6jMeta } from "/app/pages/put-in/list.vue?macro=true";
import { default as registration_45finishs70TsJw52tMeta } from "/app/pages/registration-finish.vue?macro=true";
import { default as index6hE9iHPEcfMeta } from "/app/pages/registration/corporate/index.vue?macro=true";
import { default as step_452b0NCAP2OEvMeta } from "/app/pages/registration/corporate/step-2.vue?macro=true";
import { default as step_453zujPSPWuSDMeta } from "/app/pages/registration/corporate/step-3.vue?macro=true";
import { default as step_454ItLAwQpxjQMeta } from "/app/pages/registration/corporate/step-4.vue?macro=true";
import { default as step_455j23dw0fIffMeta } from "/app/pages/registration/corporate/step-5.vue?macro=true";
import { default as step_456E4nuA3oyDxMeta } from "/app/pages/registration/corporate/step-6.vue?macro=true";
import { default as step_4571l8Vr8rkcOMeta } from "/app/pages/registration/corporate/step-7.vue?macro=true";
import { default as step_458mrt5WginxcMeta } from "/app/pages/registration/corporate/step-8.vue?macro=true";
import { default as step_459pIdvam6fgpMeta } from "/app/pages/registration/corporate/step-9.vue?macro=true";
import { default as indexTJMrDyBmZWMeta } from "/app/pages/registration/private/index.vue?macro=true";
import { default as step_452dGoskU5PUGMeta } from "/app/pages/registration/private/step-2.vue?macro=true";
import { default as step_453s9HgmKepykMeta } from "/app/pages/registration/private/step-3.vue?macro=true";
import { default as step_454dcJReksp2eMeta } from "/app/pages/registration/private/step-4.vue?macro=true";
import { default as step_455QBWtahOnSsMeta } from "/app/pages/registration/private/step-5.vue?macro=true";
import { default as step_456soaKCEZSj8Meta } from "/app/pages/registration/private/step-6.vue?macro=true";
import { default as step_457K0i3SHLi4mMeta } from "/app/pages/registration/private/step-7.vue?macro=true";
import { default as step_458eofddJg50MMeta } from "/app/pages/registration/private/step-8.vue?macro=true";
import { default as registrationre4qBsMVLdMeta } from "/app/pages/registration.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as addresswSXtiSkbKGMeta } from "/app/pages/settings/address.vue?macro=true";
import { default as emailbtxlYXJaMjMeta } from "/app/pages/settings/email.vue?macro=true";
import { default as languageLhLfuILgmcMeta } from "/app/pages/settings/language.vue?macro=true";
import { default as namerxgdIrTpibMeta } from "/app/pages/settings/name.vue?macro=true";
import { default as phonecGCaINgAPTMeta } from "/app/pages/settings/phone.vue?macro=true";
import { default as representativeCollectorxvCJUoMgcdMeta } from "/app/pages/settings/representativeCollector.vue?macro=true";
import { default as representativeSupplierXMIQc1oVPLMeta } from "/app/pages/settings/representativeSupplier.vue?macro=true";
import { default as usernamewNhMtZVhVUMeta } from "/app/pages/settings/username.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as signinUhHmpbjN2pMeta } from "/app/pages/signin.vue?macro=true";
import { default as _91uid_93xuiuYLcG36Meta } from "/app/pages/take-out/[uid].vue?macro=true";
import { default as listzWyfc5uFwkMeta } from "/app/pages/take-out/list.vue?macro=true";
import { default as notificationskdBIBXNJrYMeta } from "/app/pages/user/notifications.vue?macro=true";
import { default as profileWu1o0nyKd6Meta } from "/app/pages/user/profile.vue?macro=true";
import { default as settingsBeRaS6K39rMeta } from "/app/pages/user/settings.vue?macro=true";
import { default as userCXh5rkXRCzMeta } from "/app/pages/user.vue?macro=true";
export default [
  {
    name: "activate",
    path: "/activate",
    meta: activateWt1MltF0fhMeta || {},
    component: () => import("/app/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: "archive",
    path: "/archive",
    meta: archivek5fmITgSJqMeta || {},
    component: () => import("/app/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: "book-locker",
    path: "/book-locker",
    meta: book_45lockerJEhdloGqqgMeta || {},
    component: () => import("/app/pages/book-locker.vue").then(m => m.default || m),
    children: [
  {
    name: "book-locker-code",
    path: "code",
    component: () => import("/app/pages/book-locker/code.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-DEPRECATED_collecting",
    path: "DEPRECATED_collecting",
    component: () => import("/app/pages/book-locker/DEPRECATED_collecting.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-finish",
    path: "finish",
    component: () => import("/app/pages/book-locker/finish.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-involved",
    path: "involved",
    component: () => import("/app/pages/book-locker/involved.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-location",
    path: "location",
    component: () => import("/app/pages/book-locker/location.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-locker-size",
    path: "locker-size",
    component: () => import("/app/pages/book-locker/locker-size.vue").then(m => m.default || m)
  },
  {
    name: "book-locker-timerange",
    path: "timerange",
    component: () => import("/app/pages/book-locker/timerange.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "collect",
    path: "/collect",
    meta: collectDYhiYvZTDGMeta || {},
    component: () => import("/app/pages/collect.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "deliver",
    path: "/deliver",
    meta: deliverWYIcH8LVKyMeta || {},
    component: () => import("/app/pages/deliver.vue").then(m => m.default || m)
  },
  {
    name: "firstStartSignin",
    path: "/firstStartSignin",
    component: () => import("/app/pages/firstStartSignin.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: new_45passwordjRHibjZFxMMeta || {},
    component: () => import("/app/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications883AI3QFtaMeta || {},
    component: () => import("/app/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-driver-check-data",
    path: "/onboarding/driver/check-data",
    meta: check_45data9CCxnQ9mmQMeta || {},
    component: () => import("/app/pages/onboarding/driver/check-data.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-driver",
    path: "/onboarding/driver",
    meta: indexoiPDB37jPRMeta || {},
    component: () => import("/app/pages/onboarding/driver/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset39IAnjWvPiMeta || {},
    component: () => import("/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "put-in-uid",
    path: "/put-in/:uid()",
    meta: _91uid_93nELhhQcqisMeta || {},
    component: () => import("/app/pages/put-in/[uid].vue").then(m => m.default || m)
  },
  {
    name: "put-in-list",
    path: "/put-in/list",
    meta: listIzlsMZUP6jMeta || {},
    component: () => import("/app/pages/put-in/list.vue").then(m => m.default || m)
  },
  {
    name: "registration-finish",
    path: "/registration-finish",
    meta: registration_45finishs70TsJw52tMeta || {},
    component: () => import("/app/pages/registration-finish.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationre4qBsMVLdMeta || {},
    component: () => import("/app/pages/registration.vue").then(m => m.default || m),
    children: [
  {
    name: "registration-corporate",
    path: "corporate",
    meta: index6hE9iHPEcfMeta || {},
    component: () => import("/app/pages/registration/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-2",
    path: "corporate/step-2",
    meta: step_452b0NCAP2OEvMeta || {},
    component: () => import("/app/pages/registration/corporate/step-2.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-3",
    path: "corporate/step-3",
    meta: step_453zujPSPWuSDMeta || {},
    component: () => import("/app/pages/registration/corporate/step-3.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-4",
    path: "corporate/step-4",
    meta: step_454ItLAwQpxjQMeta || {},
    component: () => import("/app/pages/registration/corporate/step-4.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-5",
    path: "corporate/step-5",
    meta: step_455j23dw0fIffMeta || {},
    component: () => import("/app/pages/registration/corporate/step-5.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-6",
    path: "corporate/step-6",
    meta: step_456E4nuA3oyDxMeta || {},
    component: () => import("/app/pages/registration/corporate/step-6.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-7",
    path: "corporate/step-7",
    meta: step_4571l8Vr8rkcOMeta || {},
    component: () => import("/app/pages/registration/corporate/step-7.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-8",
    path: "corporate/step-8",
    meta: step_458mrt5WginxcMeta || {},
    component: () => import("/app/pages/registration/corporate/step-8.vue").then(m => m.default || m)
  },
  {
    name: "registration-corporate-step-9",
    path: "corporate/step-9",
    meta: step_459pIdvam6fgpMeta || {},
    component: () => import("/app/pages/registration/corporate/step-9.vue").then(m => m.default || m)
  },
  {
    name: "registration-private",
    path: "private",
    meta: indexTJMrDyBmZWMeta || {},
    component: () => import("/app/pages/registration/private/index.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-2",
    path: "private/step-2",
    meta: step_452dGoskU5PUGMeta || {},
    component: () => import("/app/pages/registration/private/step-2.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-3",
    path: "private/step-3",
    meta: step_453s9HgmKepykMeta || {},
    component: () => import("/app/pages/registration/private/step-3.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-4",
    path: "private/step-4",
    meta: step_454dcJReksp2eMeta || {},
    component: () => import("/app/pages/registration/private/step-4.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-5",
    path: "private/step-5",
    meta: step_455QBWtahOnSsMeta || {},
    component: () => import("/app/pages/registration/private/step-5.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-6",
    path: "private/step-6",
    meta: step_456soaKCEZSj8Meta || {},
    component: () => import("/app/pages/registration/private/step-6.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-7",
    path: "private/step-7",
    meta: step_457K0i3SHLi4mMeta || {},
    component: () => import("/app/pages/registration/private/step-7.vue").then(m => m.default || m)
  },
  {
    name: "registration-private-step-8",
    path: "private/step-8",
    meta: step_458eofddJg50MMeta || {},
    component: () => import("/app/pages/registration/private/step-8.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search",
    path: "/search",
    meta: searchfcnTL5fl5XMeta || {},
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-address",
    path: "address",
    meta: addresswSXtiSkbKGMeta || {},
    component: () => import("/app/pages/settings/address.vue").then(m => m.default || m)
  },
  {
    name: "settings-email",
    path: "email",
    meta: emailbtxlYXJaMjMeta || {},
    component: () => import("/app/pages/settings/email.vue").then(m => m.default || m)
  },
  {
    name: "settings-language",
    path: "language",
    meta: languageLhLfuILgmcMeta || {},
    component: () => import("/app/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-name",
    path: "name",
    meta: namerxgdIrTpibMeta || {},
    component: () => import("/app/pages/settings/name.vue").then(m => m.default || m)
  },
  {
    name: "settings-phone",
    path: "phone",
    meta: phonecGCaINgAPTMeta || {},
    component: () => import("/app/pages/settings/phone.vue").then(m => m.default || m)
  },
  {
    name: "settings-representativeCollector",
    path: "representativeCollector",
    meta: representativeCollectorxvCJUoMgcdMeta || {},
    component: () => import("/app/pages/settings/representativeCollector.vue").then(m => m.default || m)
  },
  {
    name: "settings-representativeSupplier",
    path: "representativeSupplier",
    meta: representativeSupplierXMIQc1oVPLMeta || {},
    component: () => import("/app/pages/settings/representativeSupplier.vue").then(m => m.default || m)
  },
  {
    name: "settings-username",
    path: "username",
    meta: usernamewNhMtZVhVUMeta || {},
    component: () => import("/app/pages/settings/username.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinUhHmpbjN2pMeta || {},
    component: () => import("/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "take-out-uid",
    path: "/take-out/:uid()",
    meta: _91uid_93xuiuYLcG36Meta || {},
    component: () => import("/app/pages/take-out/[uid].vue").then(m => m.default || m)
  },
  {
    name: "take-out-list",
    path: "/take-out/list",
    meta: listzWyfc5uFwkMeta || {},
    component: () => import("/app/pages/take-out/list.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: userCXh5rkXRCzMeta || {},
    component: () => import("/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user-notifications",
    path: "notifications",
    meta: notificationskdBIBXNJrYMeta || {},
    component: () => import("/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-profile",
    path: "profile",
    meta: profileWu1o0nyKd6Meta || {},
    component: () => import("/app/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-settings",
    path: "settings",
    meta: settingsBeRaS6K39rMeta || {},
    component: () => import("/app/pages/user/settings.vue").then(m => m.default || m)
  }
]
  }
]