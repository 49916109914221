<template>
  <button
      type="button"
      class="btn-size-base secondary-button text-custom-red font-normal flex gap-3 items-center justify-center"
      @click="problemStore.showModal = true"
  >
    <ExclamationTriangleIcon class="h-5 w-5"></ExclamationTriangleIcon>
    Problem melden
  </button>
</template>

<script setup lang="ts">
import { ExclamationTriangleIcon } from "@radix-icons/vue";

const problemStore = useProblemStore()
</script>

<style scoped>
/* Optional: Additional styles if needed */
</style>
