<template>
  <button
      :type="type"
      class="btn-size-base primary-button"
      @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script setup lang="ts">

const props = defineProps({
  type: {
    type: String,
    default: 'button',
    validator: (value: string) => ['button', 'submit', 'reset'].includes(value),
  },
})

const emit = defineEmits(['click'])

const handleClick = (event: Event) => {
  emit('click', event)
}
</script>

<style scoped>
/* Optional: Additional styles if needed */
</style>
