<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'
import { CrossCircledIcon, ExclamationTriangleIcon } from "@radix-icons/vue";

const problemStore = useProblemStore()
const description = ref(null)

const report = async () => {
  await problemStore.sendReport(description.value)
  problemStore.showModal = false
  // TODO: show Toast?
}
</script>

<template>
  <DialogRoot v-model:open="problemStore.showModal" >
    <DialogPortal>
      <DialogOverlay class="bg-custom-blue-dark/50 data-[state=open]:animate-overlayShow fixed inset-0 z-300" />
      <DialogContent
          class="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-[100]"
      >
        <div class="flex flex-col justify-center items-center gap-3 mb-5">
          <div class="bg-custom-beige w-[65px] h-[65px] rounded-full text-custom-red flex items-center justify-center">
            <ExclamationTriangleIcon class="w-7 h-7"></ExclamationTriangleIcon>
          </div>
          <DialogTitle class="text-custom-blue-dark mt-[10px]  text-2xl font-bold text-center">Probleme melden</DialogTitle>
          <DialogDescription class="text-center">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo  ligula eget dolor. Aenean massa. Cum sociis natoque.
          </DialogDescription>
        </div>
        <div class="mb-5">
          <div class="text-xl text-custom-blue-dark font-bold mb-2">
            Problembeschreibung
          </div>
          <textarea v-model="description" class="custom-input" rows="8">
            Bitte beschreibe das Problem
          </textarea>

        </div>
        <div>
          <button class="red-button btn-size-base" @click="report">
            Problem jetzt melden
          </button>
        </div>
        <DialogClose
            class="absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
            aria-label="Close"
        >
          <CrossCircledIcon class="w-6 h-6" />
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>