import { defineStore } from 'pinia'
import {useStorage} from "@vueuse/core";
import { type User } from "~/types/User";
import type {BookingData} from "~/types/dtos/BookingData";

export const useUserStore = defineStore('user', () => {
    const user = useStorage('user', <null|User>null)
    const auth = useAuth()
    function setUser(newUser: User) {
        user.value = newUser
    }
    function clearUser() {
        user.value = null
    }
    function getUser() {
        if (!user.value) {
            if (auth.data?.value?.user) {
                user.value = auth.data.value.user as User
                return user
            }
            const { data, status } = useAsyncData(
                'fetchMe',
                () => $fetch('/api/me'),
                {
                    dedupe: 'defer'
                }
            )
            if (status.value === 'success' && data.value) {
                console.log(data.value)
                user.value = data.value as User
            }
        }

        return user;
    }

    async function updateUser(newData: User) {
        setUser({...user.value, ...newData})
        try {
            const { data, status } = useAsyncData(
                'updateMe',
                () => $fetch('/api/me', {
                    method: 'PATCH',
                    body: newData,
                }),
                {
                    dedupe: 'defer'
                }
            )

            if (status.value === 'success' && data.value) {
                user.value = data.value as User
            }
        } catch (error) {
            console.error('Failed to update user:', error);
            throw error;
        }
    }

    async function updateAddress(address) {
        try {
            const { data, status } = useAsyncData(
                'updateMyAddress',
                () => $fetch('/api/my-address', {
                    method: 'PATCH',
                    body: address,
                }),
                {
                    dedupe: 'defer'
                }
            )
        } catch (error) {
            console.error('Failed to update user:', error);
            throw error;
        }
    }

    return {
        user,
        setUser,
        getUser,
        clearUser,
        updateUser,
        updateAddress
    }
})
